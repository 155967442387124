import React, { useState, useRef } from "react";

import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";

const AsyncCsvLink = ({ fileName, csvHeaders, fetchData }) => {
  const csvLink = useRef();
  const [data, setData] = useState([]);

  const handleDownloadClick = () => {
    fetchData().then((data) => {
      setData(data);
      if (csvLink && csvLink.current) {
        csvLink.current.link.click();
      }
    });
  };

  return (
    <div>
      <Button color="primary" onClick={handleDownloadClick}>
        {fileName}
      </Button>

      <CSVLink data={data} headers={csvHeaders} filename={fileName} className="hidden" ref={csvLink} target="_blank" />
    </div>
  );
};

export default AsyncCsvLink;
