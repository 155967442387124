import React, { useEffect, useState } from "react";
import { Loading, Page } from "src/components";
import { Vehicle, vehicleService } from "src/services";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import { EditVehicleDialog } from "./components";

const useStyles = makeStyles((theme) => ({
  actionButtonColumn: {
    textAlign: "right",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 550,
    },
  })
)(TableCell);

const Vehicles: React.FC = () => {
  const classes = useStyles();

  const [openEditVehicleDialog, setOpenEditVehicleDialog] =
    React.useState(false);
  const [vehicleBeingEdited, setVehicleBeingEdited] = React.useState<Vehicle>();

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    vehicleService.getVehicles().then((result) => {
      setVehicles(result.vehicles);
      setLoading(false);
    });
  }, [shouldRefresh]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  const handleEditVehicle = (vehicle: Vehicle) => {
    setVehicleBeingEdited(vehicle);
    setOpenEditVehicleDialog(true);
  };

  const handleEditVehicleDialogClose = () => {
    setVehicleBeingEdited(undefined);
    setOpenEditVehicleDialog(false);
    refresh();
  };

  return (
    <Page section="Management" title="Vehicles">
      {vehicleBeingEdited && (
        <EditVehicleDialog
          vehicle={vehicleBeingEdited}
          open={openEditVehicleDialog}
          onClose={handleEditVehicleDialogClose}
        />
      )}

      <Paper className={classes.paper}>
        <Table aria-label="vehicles">
          <TableHead>
            <TableRow>
              <StyledTableCell>Vehicle ID</StyledTableCell>
              <StyledTableCell>Display Name</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicles.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.displayName}</TableCell>
                <TableCell className={classes.actionButtonColumn}>
                  <Button
                    className={classes.button}
                    startIcon={<EditIcon />}
                    variant="outlined"
                    size="small"
                    onClick={() => handleEditVehicle(row)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {loading && <Loading />}
    </Page>
  );
};

export default Vehicles;
