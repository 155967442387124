function authHeader(): object {
  var localUserData = localStorage.getItem("user");
  if (localUserData == null) {
    return {};
  }

  let user = JSON.parse(localUserData);
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  }

  return {};
}

export default authHeader;
