import { userService } from "src/services";

function handleJsonResponse<TResponseData>(response: Response): Promise<TResponseData> {
  return response.text().then(text => {
    try {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout();
          // eslint-disable-next-line no-restricted-globals
          location.reload(true);
        }

        const error = {
          message: response.statusText,
          status: response.status
        };

        console.log(JSON.stringify(error));
        return Promise.reject(error);
      }
      var data: TResponseData = JSON.parse(text);

      return data;
    } catch (exception) {
      console.error(exception);
      return Promise.reject(exception);
    }
  });
}

export default handleJsonResponse;
