import React, { useEffect, useState } from "react";
import { Loading, Page } from "src/components";
import { Reader, readerService } from "src/services";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import { EditReaderDialog } from "./components";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  actionButtonColumn: {
    textAlign: "right",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 550,
    },
  })
)(TableCell);

interface ReadersTableProps {
  title: string;
  readers: Reader[];
  onEditReader: (reader: Reader) => void;
}

const ReadersTable: React.FC<ReadersTableProps> = ({
  title,
  readers,
  onEditReader,
}) => {
  const classes = useStyles();

  const formatDate = (date?: Date) => {
    if (!date) return "No uploads yet";

    return moment(date).format("D MMM YYYY, h:mm:ss A");
  };

  return (
    <div>
      <h3>{title}</h3>
      <Paper className={classes.paper}>
        <Table aria-label="readers">
          <TableHead>
            <TableRow>
              <StyledTableCell>Upload Device ID</StyledTableCell>
              <StyledTableCell>System Name</StyledTableCell>
              <StyledTableCell>Display Name</StyledTableCell>
              <StyledTableCell>Assigned To</StyledTableCell>
              <StyledTableCell>Last Upload At</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {readers.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.systemName}</TableCell>
                <TableCell>{row.displayName}</TableCell>
                <TableCell>{row.associatedUserIds.join(", ")}</TableCell>
                <TableCell>{formatDate(row.lastUploadDateTime)}</TableCell>
                <TableCell className={classes.actionButtonColumn}>
                  <Button
                    className={classes.button}
                    startIcon={<EditIcon />}
                    variant="outlined"
                    size="small"
                    onClick={() => onEditReader(row)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

const Readers: React.FC = () => {
  const [openEditReaderDialog, setOpenEditReaderDialog] = React.useState(false);
  const [readerBeingEdited, setReaderBeingEdited] = React.useState<Reader>();

  const [readers, setReaders] = useState<Reader[]>([]);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    readerService.getReaders().then((result) => {
      setReaders(result.readers);
      setLoading(false);
    });
  }, [shouldRefresh]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  const handleEditReader = (reader: Reader) => {
    setReaderBeingEdited(reader);
    setOpenEditReaderDialog(true);
  };

  const handleEditReaderDialogClose = () => {
    setReaderBeingEdited(undefined);
    setOpenEditReaderDialog(false);
    refresh();
  };

  const readersNotAssignedToUser = readers.filter(
    (reader) => reader.associatedUserIds.length === 0
  );
  const readersWithAssignedUsers = readers.filter(
    (reader) => reader.associatedUserIds.length > 0
  );

  return (
    <Page section="Admin" title="Readers">
      {readerBeingEdited && (
        <EditReaderDialog
          reader={readerBeingEdited}
          open={openEditReaderDialog}
          onClose={handleEditReaderDialogClose}
        />
      )}

      {readersNotAssignedToUser.length > 0 ? (
        <ReadersTable
          title="Readers not assigned to any user"
          readers={readersNotAssignedToUser}
          onEditReader={handleEditReader}
        />
      ) : null}

      <ReadersTable
        title="Readers with users"
        readers={readersWithAssignedUsers}
        onEditReader={handleEditReader}
      />
      {loading && <Loading />}
    </Page>
  );
};

export default Readers;
