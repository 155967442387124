import { atom } from "recoil";
import { DetectionResponse } from "src/services";

export const detectionsDataState = atom<DetectionResponse>({
  key: "DASHBOARD_detections_data",
  default: {
    vehicles: [],
    tags: [],
    detections: [],
    pageNumber: 0,
    pageSize: 10,
    totalRecords: 0,
  },
});
