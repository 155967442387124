import React, { useState } from "react";
import { Loading } from "src/components";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";

import { Tag, tagService, UpdateTagRequest } from "../../../services";

interface EditTagDialogProps {
  tag: Tag;
  open: boolean;
  onClose(): void;
}

const EditTagDialog: React.FC<EditTagDialogProps> = ({ tag, open, onClose }) => {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState<string>(tag.displayName);

  const handleSave = () => {
    if (saving) {
      return;
    }

    setSaving(true);

    const updateTagRequest: UpdateTagRequest = {
      id: tag.id,
      displayName: name,
    };

    tagService.updateTag(updateTagRequest).then(() => {
      setSaving(false);
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Edit Tag</DialogTitle>

      <DialogContent>
        <TextField label="Tag ID" value={tag.systemName} margin="dense" fullWidth disabled />

        <TextField
          label="Name"
          value={name}
          margin="dense"
          autoFocus
          required
          fullWidth
          disabled={saving}
          onChange={(event) => setName(event.target.value)}
        />

        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            Save
          </Button>
        </DialogActions>

        {saving && <Loading />}
      </DialogContent>
    </Dialog>
  );
};

export default EditTagDialog;
