import { authHeader } from "./";
import storageService, { StorageKey } from "./Storage";

function isDevelopmentEnvironment(): boolean {
  return process.env.NODE_ENV && process.env.NODE_ENV === "development";
}

function getApiServerUrl(): string {
  if (isDevelopmentEnvironment()) {
    return "http://localhost:30000";
  }
  return "";
}

function getContentTypeHeader(): object {
  return { "Content-Type": "application/json" };
}

function viewAsUserHeader(): object {
  const viewAsUserId = storageService.getSessionData(StorageKey.viewAsUser);
  if (!viewAsUserId) return {};

  return { "X-View-As-User": viewAsUserId };
}

function fetchFromApiResource(
  apiResource: string,
  requestMethod: string,
  headers?: string[][],
  body?: string
): Promise<Response> {
  const requestOptions: RequestInit = {
    method: requestMethod,
    headers: {
      ...authHeader(),
      ...getContentTypeHeader(),
      ...viewAsUserHeader(),
      ...headers,
    },
    body: body,
  };

  return fetch(`${getApiServerUrl()}/${apiResource}`, requestOptions);
}

export default fetchFromApiResource;
