import React, { ReactNode } from "react";

import { makeStyles, Toolbar } from "@material-ui/core";
import { Content, Footer, Header } from "@mui-treasury/layout";

import { FooterContent, HeaderContent } from "./components";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden auto",
    width: "100%",
  },
  footer: {
    marginTop: "calc(5%)",
    bottom: 0,
  },
}));

interface MinLayoutProps {
  children?: ReactNode;
}

const MinLayout: React.FC<MinLayoutProps> = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Header color={"primary"}>
        <Toolbar>
          <HeaderContent />
        </Toolbar>
      </Header>
      <div>
        <Content className={styles.content}>{children}</Content>
        <Footer className={styles.footer}>
          <FooterContent />
        </Footer>
      </div>
    </div>
  );
};

export default MinLayout;
