import React, { useState } from "react";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReaderDto, UpdateUserRequest, userService, UserDto } from "../../../services";

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  saveProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formItem: {
    marginBottom: theme.spacing(1)
  },
  selectItemIdText: {
    marginLeft: theme.spacing(1)
  }
}));

interface EditUserDialogProps {
  user: UserDto;
  availableReaders: ReaderDto[];
  open: boolean;
  onClose(): void;
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({ user, availableReaders, open, onClose }) => {
  const classes = useStyles();

  const initialReaders = availableReaders.filter(x => user.readers.findIndex(y => y.id === x.id) > -1);

  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState<string>(user.email);
  const [roles, setRoles] = useState<string[]>(user.roles);
  const [readers, setReaders] = useState<ReaderDto[]>(initialReaders);

  const availableRoles = ["admin", "user"];

  const handleSave = () => {
    if (saving) {
      return;
    }

    setSaving(true);

    const updateUserRequest: UpdateUserRequest = {
      userId: user.userId,
      email: email,
      roles: roles,
      readers: readers
    };
    userService.updateUser(updateUserRequest).then(() => {
      setSaving(false);
      onClose();
    });
  };

  const ReaderDtoLabel: React.FC<{ item: ReaderDto }> = ({ item }) => {
    return (
      <span>
        {item.displayName} {item.id !== item.displayName && <span className={classes.selectItemIdText}>({item.id})</span>}
      </span>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Edit User</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid className={classes.formItem} item xs={12}>
            <TextField label="Username" value={user.userId} margin="dense" fullWidth disabled />
          </Grid>

          <Grid className={classes.formItem} item xs={12}>
            <TextField
              label="E-Mail"
              value={email}
              type="email"
              margin="dense"
              autoFocus
              required
              fullWidth
              disabled={saving}
              onChange={event => setEmail(event.target.value)}
            />
          </Grid>

          <Grid className={classes.formItem} item xs={12}>
            <Autocomplete
              multiple
              id="roles-select"
              options={availableRoles}
              value={roles}
              onChange={(_, value) => setRoles(value)}
              getOptionLabel={option => option}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </React.Fragment>
              )}
              renderInput={params => <TextField {...params} variant="standard" label="Roles" fullWidth margin="dense" />}
            />
          </Grid>

          <Grid className={classes.formItem} item xs={12}>
            <Autocomplete
              multiple
              id="readers-select"
              options={availableReaders}
              value={readers}
              onChange={(_, value) => setReaders(value)}
              disableCloseOnSelect
              getOptionLabel={option => option.displayName}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  <ReaderDtoLabel item={option} />
                </React.Fragment>
              )}
              renderInput={params => <TextField {...params} variant="standard" label="Readers" fullWidth margin="dense" />}
            />
          </Grid>

          <Grid className={classes.formItem} item xs={12}>
            <DialogActions>
              <Button onClick={onClose} color="primary" disabled={saving}>
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={saving}>
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>

        {saving && <CircularProgress size={24} className={classes.saveProgress} />}
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDialog;
