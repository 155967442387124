import React from "react";
import { makeStyles, Theme, createStyles, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(5),
      paddingTop: theme.spacing(0),
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

interface PageProps {
  section: string;
  title: string;
  titleActions?: any;
  children: any;
}

const Page: React.FC<PageProps> = ({ section, title, titleActions, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="flex-end">
        <Grid item md={8} xs={12}>
          <div className={classes.title}>
            <Typography variant="caption">{section}</Typography>
            <Typography variant="h5">{title}</Typography>
          </div>
        </Grid>

        {titleActions && (
          <Grid item md={4} xs={12} style={{ textAlign: "right" }}>
            {titleActions}
          </Grid>
        )}

        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default Page;
