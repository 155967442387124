import moment from "moment";
import React from "react";
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { COLORS, Loading } from "src/components";
import { LineChartReport } from "src/services";

import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "relative"
    },
    title: {
      textAlign: "left",
      margin: theme.spacing(2)
    },
    divider: {
      marginBottom: theme.spacing(3)
    },
    chartContainer: {
      width: "100%",
      height: 400
    },
    wrapper: {
      position: "relative"
    }
  })
);

const formatDate = (value: any) => {
  return moment(value).format("D MMM YYYY");
};

const DateTick: React.FC<any> = ({ payload: { value }, verticalAnchor, visibleTicksCount, ...rest }) => (
  <text {...rest} className="bar-chart-tick" dy={12}>
    {formatDate(value)}
  </text>
);

interface TotalDetectionsLineChart {
  title: string;
  data: LineChartReport;
  loading: boolean;
}

const TotalDetectionsLineChart: React.FC<TotalDetectionsLineChart> = ({ title, data, loading }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.wrapper}>
            <div className={classes.chartContainer}>
              <ResponsiveContainer width="99%">
                <ComposedChart data={data.entries} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={<DateTick />} />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip labelFormatter={formatDate} />
                  <Bar yAxisId="left" dataKey="Daily Detections" barSize={5} fill={COLORS[1]} />
                  <Line yAxisId="right" type="monotone" dataKey="Cumulative Total" stroke={COLORS[0]} strokeWidth={3} />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
            {loading && <Loading />}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TotalDetectionsLineChart;
