import React from "react";
import { arrayEquals } from "src/helpers";

import {
  Checkbox,
  Chip,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import { Autocomplete } from ".";

// import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    selectAllOption: {
      minHeight: 48,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      paddingTop: 6,
      boxSizing: "border-box",
      outline: "0",
      WebkitTapHighlightColor: "transparent",
      paddingBottom: 6,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up("sm")]: {
        minHeight: "auto",
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.action.selected,
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      '&[aria-disabled="true"]': {
        opacity: 0.5,
        pointerEvents: "none",
      },
      "&:active": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  })
);

export interface ReportFilterItem {
  id: string;
  systemName: string;
  displayName: string;
}

interface ReportFilterSelectProps {
  name: string;
  label: string;
  items: ReportFilterItem[];
  selectedItems: ReportFilterItem[];
  onSelectedItemsChanged(items: ReportFilterItem[]): void;
}

const ReportFilterSelect: React.FC<ReportFilterSelectProps> = ({
  name,
  label,
  items,
  selectedItems,
  onSelectedItemsChanged,
}) => {
  const classes = useStyles();

  const handleChange = (value: ReportFilterItem[]) => {
    if (!arrayEquals(value, selectedItems)) {
      onSelectedItemsChanged(value);
    }
  };

  const renderTags = (value: ReportFilterItem[], getTagProps: any) => {
    const maxItemsShownInPreview = 5;

    var tags = value;
    if (value.length > maxItemsShownInPreview) {
      tags = value.slice(0, maxItemsShownInPreview);
    }
    var moreText =
      value.length > maxItemsShownInPreview
        ? `... ${value.length - maxItemsShownInPreview} more`
        : "";

    return (
      <div>
        {tags.map((value) => (
          <Chip
            key={value.id}
            label={value.displayName}
            {...getTagProps(value)}
            size="small"
          />
        ))}
        {moreText && (
          <Typography variant="caption" gutterBottom>
            {moreText}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        showSelectAll
        id={name}
        options={items}
        value={selectedItems}
        onChange={(_: any, value: any) => handleChange(value)}
        renderInput={(params: any) => (
          <TextField {...params} variant="standard" label={label} fullWidth />
        )}
        renderTags={renderTags}
        getOptionLabel={(option: any) => option.displayName}
        renderOption={(option: any, event: any) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={event.selected}
            />
            {option.displayName}
          </React.Fragment>
        )}
      />
    </div>
  );
};

export default ReportFilterSelect;
