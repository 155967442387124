import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "src/atoms";

import { RouteWithLayout } from "./";

interface AuthRequiredRouteWithLayoutProps {
  component: any;
  layout: any;
  path: string;
}

const AuthRequiredRouteWithLayout: React.FC<AuthRequiredRouteWithLayoutProps> = ({ ...rest }) => {
  const user = useRecoilValue(userState);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!user || !user.token) {
      setShouldRedirect(true);
    }
  }, [user]);

  return (
    <div>
      {shouldRedirect && <Redirect to="/login" />}
      {user && user.token && <RouteWithLayout {...rest} />}
    </div>
  );
};

export default AuthRequiredRouteWithLayout;
