export enum StorageKey {
  detectionsFilter = "DETECTIONS_FILTER",
  viewAsUser = "VIEW_AS_USER",
}

function saveSessionData(key: StorageKey, value: string) {
  sessionStorage.setItem(key, value);
}

function getSessionData(key: StorageKey): string | null {
  return sessionStorage.getItem(key);
}

function clearSessionData(key: StorageKey) {
  sessionStorage.removeItem(key);
}

const storageService = {
  saveSessionData,
  getSessionData,
  clearSessionData,
};

export default storageService;
