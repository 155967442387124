import clsx from "clsx";
import React, { ReactElement } from "react";

import { Avatar, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Loading } from "src/components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    changeSection: {
      display: "flex",
    },
    goodColor: {
      color: "#1b5e20",
    },
    badColor: {
      color: "#b71c1c",
    },
    changeCaptionText: {
      marginLeft: theme.spacing(1),
    },
    cardIconAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    cardIcon: {
      fontSize: "xx-large",
    },
    wrapper: {
      position: "relative",
    },
  })
);

interface NumberSummaryCardProps {
  title: string;
  number?: number;
  change?: number;
  changePostfix?: string;
  changeCaption?: string;
  changeRating?: "bad" | "good";
  icon: ReactElement;
  iconColor: string;
  loading: boolean;
}

const NumberSummaryCard: React.FC<NumberSummaryCardProps> = ({
  title,
  number,
  change,
  changePostfix,
  changeCaption,
  changeRating,
  icon,
  iconColor,
  loading,
}) => {
  const classes = useStyles();

  const styledIcon = React.cloneElement(icon, { className: classes.cardIcon });

  return (
    <Paper className={classes.paper}>
      <div className={classes.wrapper}>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography variant="overline" display="block" gutterBottom>
              {title}
            </Typography>
            {number ? (
              <Typography variant="h4" display="block">
                {number.toLocaleString("en-US")}
              </Typography>
            ) : (
              <p>No data is available for the selected date range</p>
            )}
          </Grid>
          <Grid item xs={2}>
            <Avatar className={classes.cardIconAvatar} style={{ backgroundColor: iconColor }}>
              {styledIcon}
            </Avatar>
          </Grid>
          {change && (
            <Grid item xs={12}>
              <span className={classes.changeSection}>
                {change > 0 && (
                  <ArrowUpwardIcon
                    className={clsx({
                      [classes.goodColor]: changeRating === "good",
                      [classes.badColor]: changeRating === "bad",
                    })}
                  />
                )}
                {change < 0 && (
                  <ArrowDownwardIcon
                    className={clsx({
                      [classes.goodColor]: changeRating === "good",
                      [classes.badColor]: changeRating === "bad",
                    })}
                  />
                )}
                <Typography
                  variant="body2"
                  className={clsx({
                    [classes.goodColor]: changeRating === "good",
                    [classes.badColor]: changeRating === "bad",
                  })}
                >
                  {Math.abs(change)}
                  {changePostfix}
                </Typography>
                <Typography variant="caption" className={classes.changeCaptionText}>
                  {changeCaption}
                </Typography>
              </span>
            </Grid>
          )}
        </Grid>
        {loading && <Loading />}
      </div>
    </Paper>
  );
};

export default NumberSummaryCard;
