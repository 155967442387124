import React from "react";
import { Typography } from "@material-ui/core";

const FooterContent: React.FC = () => (
  <div style={{ maxWidth: 700, margin: "auto", textAlign: "center" }}>
    <Typography variant="caption" align={"center"}>
      © Copyright Orbit Communications {new Date().getFullYear()}
    </Typography>
  </div>
);

export default FooterContent;
