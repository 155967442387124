import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Loading } from "src/components";

import { CustomPaging, DataTypeProvider, PagingState, Sorting, SortingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { detectionsDataState, detectionsLoadingState, detectionsTableControlState } from "../atoms";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      overflowX: "auto",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      position: "relative",
      padding: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    chartTitle: {
      textAlign: "center",
      marginBottom: theme.spacing(5),
    },
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(4),
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    clearIcon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    clearListItemText: {},
    reportFilter: {},
    dateRangePicker: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const DetectionsDataTable: React.FC = () => {
  const classes = useStyles();

  const detectionsResponse = useRecoilValue(detectionsDataState);
  const detectionsLoading = useRecoilValue(detectionsLoadingState);

  const [detectionsTableControlData, setDetectionsTaleControlData] = useRecoilState(detectionsTableControlState);

  const handlePageNumberChange = (pageNumber: number) => {
    setDetectionsTaleControlData({ ...detectionsTableControlData, pageNumber: pageNumber });
  };

  const handlePageSizeChange = (pageSize: number) => {
    setDetectionsTaleControlData({ ...detectionsTableControlData, pageSize: pageSize });
  };

  const handleSortChange = (sorts: Sorting[]) => {
    setDetectionsTaleControlData({
      ...detectionsTableControlData,
      sortItems: sorts.map((x) => ({ columnName: x.columnName, sortDirection: x.direction })),
    });
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        rows={detectionsResponse.detections}
        columns={[
          { name: "date", title: "Date" },
          { name: "time", title: "Time" },
          { name: "vehicle", title: "Vehicle" },
          { name: "tag", title: "Tag" },
        ]}
      >
        <DataTypeProvider
          formatterComponent={({ value }) => value.substring(0, value.indexOf("T")).replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1")}
          for={["date"]}
        />

        <PagingState
          currentPage={detectionsResponse.pageNumber}
          onCurrentPageChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          pageSize={detectionsResponse.pageSize}
        />

        <CustomPaging totalCount={detectionsResponse.totalRecords} />
        <SortingState defaultSorting={[{ columnName: "date", direction: "asc" }]} onSortingChange={handleSortChange} />

        <Table />
        <TableHeaderRow showSortingControls />
        <PagingPanel pageSizes={[10, 20, 50]} />
        {detectionsLoading && <Loading />}
      </Grid>
    </Paper>
  );
};

export default DetectionsDataTable;
