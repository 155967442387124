import { fetchFromApiResource, handleJsonResponse } from "../helpers";

export interface Vehicle {
  id: string;
  systemName: string;
  displayName: string;
}

export interface UpdateVehicleRequest {
  id: string;
  displayName: string;
}

export interface GetVehiclesResponse {
  vehicles: Vehicle[];
}

function getVehicles(): Promise<GetVehiclesResponse> {
  return fetchFromApiResource(`api/v1/logrecords/vehicles`, "GET").then(
    (response) => handleJsonResponse<GetVehiclesResponse>(response)
  );
}

function updateVehicle(
  updateVehicleRequest: UpdateVehicleRequest
): Promise<void> {
  return fetchFromApiResource(
    `api/v1/logrecords/vehicles`,
    "PUT",
    undefined,
    JSON.stringify(updateVehicleRequest)
  ).then(() => new Promise<void>((resolve) => resolve()));
}

const vehicleService = {
  getVehicles,
  updateVehicle,
};

export default vehicleService;
