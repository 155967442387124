import moment from "moment";
import React from "react";
import { useRecoilValue } from "recoil";

import MomentUtils from "@date-io/moment";
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DetectionsFilter } from "../../../services";
import { dashboardDataState } from "../atoms";
import { ReportFilterSelect } from "../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterButton: {
      marginTop: theme.spacing(2),
    },
    filterDrawerContent: {
      width: 400,
      padding: theme.spacing(2),
      display: "flex",
      height: "100%",
      flexDirection: "column",
    },
    filterList: {
      width: "auto",
    },
    filterSectionHeader: {
      marginTop: theme.spacing(2),
    },
  })
);

interface FilterDrawerProps {
  open: boolean;
  filter: DetectionsFilter;
  setFilter(filter: DetectionsFilter): void;
  onApplyFilter(): void;
  onClearFilter(): void;
  onClose(): void;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  open,
  filter,
  setFilter,
  onApplyFilter,
  onClearFilter,
  onClose,
}) => {
  const classes = useStyles();

  const dashboardResponse = useRecoilValue(dashboardDataState);

  const { tags, vehicles } = dashboardResponse;

  const close = () => {
    onClose();
  };

  const applyFilters = () => {
    onApplyFilter();
    close();
  };

  const clearFilters = () => {
    onClearFilter();
  };

  const handleStartDateChanged = (startDate: moment.Moment | null) => {
    var endDate = filter.endDate;
    if (startDate && endDate && startDate > endDate) {
      endDate = startDate;
    }

    setFilter({ ...filter, startDate: startDate, endDate: endDate });
  };

  const handleEndDateChanged = (endDate: moment.Moment | null) => {
    var startDate = filter.startDate;
    if (startDate && endDate && endDate < startDate) {
      startDate = endDate;
    }

    setFilter({ ...filter, startDate: startDate, endDate: endDate });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Drawer anchor="right" open={open} onClose={close}>
        <div className={classes.filterDrawerContent}>
          <div style={{ flexGrow: 0 }}>
            <Button startIcon={<CloseIcon />} onClick={close}>
              Close
            </Button>
          </div>

          <div style={{ flexGrow: 1 }}>
            <List className={classes.filterList}>
              <div className={classes.filterSectionHeader}>
                <Typography variant="overline">Filter Options</Typography>
                <Divider />
              </div>
              <ListItem>
                <DatePicker
                  fullWidth
                  margin="normal"
                  id="from-date-picker"
                  label="From date"
                  format="D MMM YYYY"
                  value={filter.startDate}
                  onChange={(date) => handleStartDateChanged(date)}
                />
              </ListItem>
              <ListItem>
                <DatePicker
                  fullWidth
                  margin="normal"
                  id="to-date-picker"
                  label="To date"
                  format="D MMM YYYY"
                  value={filter.endDate}
                  onChange={(date) => handleEndDateChanged(date)}
                />
              </ListItem>
              <ListItem>
                <ReportFilterSelect
                  name="tags"
                  label="Tags"
                  items={tags}
                  selectedItems={filter.tags}
                  onSelectedItemsChanged={(items) =>
                    setFilter({ ...filter, tags: items })
                  }
                />
              </ListItem>
              <ListItem>
                <ReportFilterSelect
                  name="vehicles"
                  label="Vehicles"
                  items={vehicles}
                  selectedItems={filter.vehicles}
                  onSelectedItemsChanged={(items) =>
                    setFilter({ ...filter, vehicles: items })
                  }
                />
              </ListItem>
            </List>
          </div>
          <div>
            <Button
              variant="contained"
              fullWidth
              startIcon={<DeleteOutlineIcon />}
              className={classes.filterButton}
              onClick={clearFilters}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.filterButton}
              onClick={applyFilters}
            >
              Apply Filters
            </Button>
          </div>
        </div>
      </Drawer>
    </MuiPickersUtilsProvider>
  );
};

export default FilterDrawer;
