import React, { useState } from "react";
import { Loading } from "src/components";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";

import { UpdateReaderRequest, Reader, readerService } from "../../../services";

interface EditReaderDialogProps {
  reader: Reader;
  open: boolean;
  onClose(): void;
}

const EditReaderDialog: React.FC<EditReaderDialogProps> = ({ reader, open, onClose }) => {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState<string>(reader.displayName);

  const handleSave = () => {
    if (saving) {
      return;
    }

    setSaving(true);

    const updateReaderRequest: UpdateReaderRequest = {
      id: reader.id,
      displayName: name
    };

    readerService.updateReader(updateReaderRequest).then(() => {
      setSaving(false);
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Edit Reader</DialogTitle>

      <DialogContent>
        <TextField label="Reader ID" value={reader.id} margin="dense" fullWidth disabled />
        <TextField label="System Name" value={reader.systemName} margin="dense" fullWidth disabled />

        <TextField
          label="Name"
          value={name}
          margin="dense"
          autoFocus
          required
          fullWidth
          disabled={saving}
          onChange={event => setName(event.target.value)}
        />

        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            Save
          </Button>
        </DialogActions>

        {saving && <Loading />}
      </DialogContent>
    </Dialog>
  );
};

export default EditReaderDialog;
