import React from "react";
import { Route } from "react-router-dom";

interface RouteWithLayoutProps {
  component: any;
  layout: any;
  path: string;
}

const RouteWithLayout: React.FC<RouteWithLayoutProps> = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
