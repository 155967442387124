import moment from "moment";
import React from "react";
import { Loading } from "src/components";
import { LogBundle, logBundleService } from "src/services";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { AsyncCsvLink } from "./";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { userState } from "src/atoms";

const csvHeaders = [
  { label: "VehicleId", key: "vehicleId" },
  { label: "RecordDate", key: "recordDate" },
  { label: "RecordTime", key: "recordTime" },
  { label: "RecordType", key: "recordType" },
  { label: "Description", key: "description" },
  { label: "Parameters", key: "parameter1" },
  { label: "Parameters", key: "parameter2" },
  { label: "Parameters", key: "parameter3" },
  { label: "Parameters", key: "parameter4" },
];

interface LogBundleDataGridProps {
  logBundles: LogBundle[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  loading: boolean;
  onPageNumberChange(pageNumber: number): void;
  onPageSizeChange(pageSize: number): void;
  onDeleteLogBundle(logBundleId: number): void;
}

interface ActionMenuProps {
  onDeleteLogBundle(): void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ onDeleteLogBundle }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteLogBundle = () => {
    closeMenu();
    onDeleteLogBundle();
  };

  return (
    <React.Fragment>
      <Button
        endIcon={<ExpandMoreIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => handleDeleteLogBundle()}>Delete</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const LogBundleDataGrid: React.FC<LogBundleDataGridProps> = ({
  logBundles,
  pageNumber,
  pageSize,
  totalRecords,
  loading,
  onPageNumberChange,
  onPageSizeChange,
  onDeleteLogBundle,
}) => {
  const user = useRecoilValue(userState);

  const formatDate = (date: Date) => {
    return moment(date).format("D MMM YYYY, h:mm:ss A");
  };

  const tableRecords = logBundles.map((l) => ({
    vehicle: l.vehicleName,
    date: formatDate(l.date),
    logs: l,
    actions: l,
  }));

  const columns = [
    { name: "vehicle", title: "Vehicle" },
    { name: "date", title: "Date" },
    { name: "logs", title: "Logs" },
  ];

  if (user?.roles.includes("admin")) {
    columns.push({ name: "actions", title: "Actions" });
  }

  return (
    <Grid rows={tableRecords} columns={columns}>
      <DataTypeProvider
        formatterComponent={({ value }) => (
          <AsyncCsvLink
            fileName={value.logBundleFileName}
            csvHeaders={csvHeaders}
            fetchData={() =>
              logBundleService
                .getLogBundle(value.logBundleId)
                .then((bundle) => bundle.logBundleRecords)
            }
          />
        )}
        for={["logs"]}
      />

      <DataTypeProvider
        formatterComponent={({ value }) => (
          <ActionMenu
            onDeleteLogBundle={() => onDeleteLogBundle(value.logBundleId)}
          />
        )}
        for={["actions"]}
      />

      <PagingState
        currentPage={pageNumber}
        onCurrentPageChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
      />

      <CustomPaging totalCount={totalRecords} />

      <Table />
      <TableHeaderRow />
      <PagingPanel pageSizes={[10, 20, 50]} />
      {loading && <Loading />}
    </Grid>
  );
};

export default LogBundleDataGrid;
