import { fetchFromApiResource, handleJsonResponse } from "../helpers";

export interface Tag {
  id: string;
  systemName: string;
  displayName: string;
}

export interface UpdateTagRequest {
  id: string;
  displayName: string;
}

export interface GetTagsResponse {
  tags: Tag[];
}

function getTags(): Promise<GetTagsResponse> {
  return fetchFromApiResource(`api/v1/logrecords/tags`, "GET").then(response => handleJsonResponse<GetTagsResponse>(response));
}

function updateTag(updateTagRequest: UpdateTagRequest): Promise<void> {
  return fetchFromApiResource(`api/v1/logrecords/tags`, "PUT", undefined, JSON.stringify(updateTagRequest)).then(
    () => new Promise<void>(resolve => resolve())
  );
}

const tagService = {
  getTags,
  updateTag
};

export default tagService;
