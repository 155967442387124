import React from "react";
import { useRecoilValue } from "recoil";
import { userState } from "src/atoms";

import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import GetAppIcon from "@material-ui/icons/GetApp";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import storageService, { StorageKey } from "src/helpers/Storage";

interface NavigationItemData {
  label: string;
  route: string;
  icon: JSX.Element;
}

const analyticsNavigationItems: NavigationItemData[] = [
  {
    label: "Dashboard",
    route: "/",
    icon: <DashboardIcon />,
  },
  {
    label: "Uploaded Logs",
    route: "/uploadedlogs",
    icon: <GetAppIcon />,
  },
];

const managementNavigationItems: NavigationItemData[] = [
  {
    label: "Tags",
    route: "/tags",
    icon: <DirectionsRunIcon />,
  },
  {
    label: "Vehicles",
    route: "/vehicles",
    icon: <LocalShippingIcon />,
  },
];

const adminNavigationItems: NavigationItemData[] = [
  {
    label: "User",
    route: "/admin/users",
    icon: <SupervisorAccountIcon />,
  },
  {
    label: "Readers",
    route: "/admin/readers",
    icon: <TabletAndroidIcon />,
  },
];

interface NavigationItemProps {
  key: string;
  activeRoute: string;
  onNavigate(route: string): void;
  item: NavigationItemData;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  activeRoute,
  onNavigate,
  item,
}) => (
  <ListItem
    key={item.label}
    selected={item.route === activeRoute}
    button
    onClick={() => onNavigate(item.route)}
  >
    <ListItemIcon>
      <Icon>{item.icon}</Icon>
    </ListItemIcon>
    <ListItemText
      primary={item.label}
      primaryTypographyProps={{ noWrap: true }}
    />
  </ListItem>
);

interface NavigationContentProps {
  activeRoute: string;
  onNavigate(route: string): void;
}

const SectionHeader: React.FC<{ label: string }> = ({ label }) => (
  <ListItem>
    <Typography variant="overline">{label}</Typography>
  </ListItem>
);

const NavigationContent: React.FC<NavigationContentProps> = ({
  activeRoute,
  onNavigate,
}) => {
  const user = useRecoilValue(userState);
  const isUserAnAdmin = user && user.roles.indexOf("admin") > -1;
  const viewAsUserId = storageService.getSessionData(StorageKey.viewAsUser);

  return (
    <List>
      <SectionHeader label={"Analytics"} />

      {analyticsNavigationItems.map((item) => (
        <NavigationItem
          key={item.label}
          activeRoute={activeRoute}
          onNavigate={onNavigate}
          item={item}
        />
      ))}

      <SectionHeader label={"Management"} />

      {managementNavigationItems.map((item) => (
        <NavigationItem
          key={item.label}
          activeRoute={activeRoute}
          onNavigate={onNavigate}
          item={item}
        />
      ))}

      {!viewAsUserId && isUserAnAdmin && (
        <div>
          <SectionHeader label={"Admin"} />

          {adminNavigationItems.map((item) => (
            <NavigationItem
              key={item.label}
              activeRoute={activeRoute}
              onNavigate={onNavigate}
              item={item}
            />
          ))}
        </div>
      )}
    </List>
  );
};

export default NavigationContent;
