import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useHeaderStyles, useSidebarStyles } from "src/helpers";

import { makeStyles, Toolbar } from "@material-ui/core";
import {
  CollapseBtn,
  CollapseIcon,
  Content,
  Footer,
  Header,
  Sidebar,
  SidebarTrigger,
  SidebarTriggerIcon,
} from "@mui-treasury/layout";

import history from "../helpers/History";
import { FooterContent, HeaderContent, NavigationContent } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  sidebarTriggerIcon: {
    color: theme.palette.primary.contrastText,
  },
  content: {
    flexGrow: 1,
    overflow: "hidden auto",
  },
  footer: {
    marginTop: "calc(1%)",
    bottom: 0,
  },
}));

interface MainLayoutProps {
  headerStyles: any;
  sidebarStyles: any;
  children?: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  const headerStyles = useHeaderStyles();
  const sidebarStyles = useSidebarStyles();

  const navigate = (route: string) => {
    history.push(route);
  };

  return (
    <div className={classes.root}>
      <Header color={"primary"}>
        <Toolbar>
          <SidebarTrigger className={headerStyles.leftTrigger}>
            <SidebarTriggerIcon className={classes.sidebarTriggerIcon} />
          </SidebarTrigger>
          <HeaderContent />
        </Toolbar>
      </Header>
      <Sidebar>
        <div className={sidebarStyles.container}>
          <NavigationContent
            activeRoute={location.pathname}
            onNavigate={navigate}
          />
        </div>
        <CollapseBtn className={sidebarStyles.collapseBtn}>
          <CollapseIcon />
        </CollapseBtn>
      </Sidebar>
      <div>
        <Content className={classes.content}>{children}</Content>
        <Footer className={classes.footer}>
          <FooterContent />
        </Footer>
      </div>
    </div>
  );
};

export default MainLayout;
