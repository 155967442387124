import React, { useState } from "react";
import { Loading } from "src/components";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import {
  UpdateVehicleRequest,
  Vehicle,
  vehicleService,
} from "../../../services";

interface EditVehicleDialogProps {
  vehicle: Vehicle;
  open: boolean;
  onClose(): void;
}

const EditVehicleDialog: React.FC<EditVehicleDialogProps> = ({
  vehicle,
  open,
  onClose,
}) => {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState<string>(vehicle.displayName);

  const handleSave = () => {
    if (saving) {
      return;
    }

    setSaving(true);

    const updateVehicleRequest: UpdateVehicleRequest = {
      id: vehicle.id,
      displayName: name,
    };

    vehicleService.updateVehicle(updateVehicleRequest).then(() => {
      setSaving(false);
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Edit Vehicle</DialogTitle>

      <DialogContent>
        <TextField
          label="Vehicle ID"
          value={vehicle.id}
          margin="dense"
          fullWidth
          disabled
        />

        <TextField
          label="Name"
          value={name}
          margin="dense"
          autoFocus
          required
          fullWidth
          disabled={saving}
          onChange={(event) => setName(event.target.value)}
        />

        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            Save
          </Button>
        </DialogActions>

        {saving && <Loading />}
      </DialogContent>
    </Dialog>
  );
};

export default EditVehicleDialog;
