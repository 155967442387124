import React, { useEffect, useState } from "react";
import { Loading, Page } from "src/components";
import { Tag, tagService } from "src/services";

import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import { EditTagDialog } from "./components";

const useStyles = makeStyles((theme) => ({
  actionButtonColumn: {
    textAlign: "right",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 550,
    },
  })
)(TableCell);

const Tags: React.FC = () => {
  const classes = useStyles();

  const [openEditTagDialog, setOpenEditTagDialog] = React.useState(false);
  const [tagBeingEdited, setTagBeingEdited] = React.useState<Tag>();

  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    tagService.getTags().then((result) => {
      setTags(result.tags);
      setLoading(false);
    });
  }, [shouldRefresh]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  const handleEditTag = (tag: Tag) => {
    setTagBeingEdited(tag);
    setOpenEditTagDialog(true);
  };

  const handleEditTagDialogClose = () => {
    setTagBeingEdited(undefined);
    setOpenEditTagDialog(false);
    refresh();
  };

  return (
    <Page section="Management" title="Tags">
      {tagBeingEdited && <EditTagDialog tag={tagBeingEdited} open={openEditTagDialog} onClose={handleEditTagDialogClose} />}

      <Paper className={classes.paper}>
        <Table aria-label="tags">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tag ID</StyledTableCell>
              <StyledTableCell>Display Name</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.systemName}</TableCell>
                <TableCell>{row.displayName}</TableCell>
                <TableCell className={classes.actionButtonColumn}>
                  <Button className={classes.button} startIcon={<EditIcon />} variant="outlined" size="small" onClick={() => handleEditTag(row)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {loading && <Loading />}
    </Page>
  );
};

export default Tags;
