import { fetchFromApiResource, handleJsonResponse } from "../helpers";
import moment from "moment";
import { Vehicle, Tag } from ".";

export interface LineChartReport {
  items: string[];
  entries: LineChartReportEntry[];
}

export interface LineChartReportEntry {
  [key: string]: any;
}

export interface NumberCardReport {
  number: number;
  change: number;
}

export interface DetectionsSummaryTableEntry {
  name: string;
  detections: number;
}

export interface DetectionRecord {
  date: Date;
  time: Date;
  vehicle: string;
  tag: string;
}

export interface SortItem {
  columnName: string;
  sortDirection: string;
}

export interface DetectionReport {
  startDate: Date;
  endDate: Date;

  detections: LineChartReport;
}

export interface DashboardReport {
  startDate: Date;
  endDate: Date;

  averageDetectionsPerMonth?: NumberCardReport;
  averageDetectionsPerWeek?: NumberCardReport;
  averageDetectionsPerDay?: NumberCardReport;
  totalDetections: LineChartReport;
  topFiveVehiclesWithDetections: DetectionsSummaryTableEntry[];
  topFiveTagsWithDetections: DetectionsSummaryTableEntry[];
}

export interface DashboardRequest {
  adminViewAsUserId?: string;
  vehicleIds: string[];
  tagIds: string[];
  dateRangeStart: string | undefined;
  dateRangeEnd: string | undefined;
}

export interface DashboardResponse {
  dashboardReport: DashboardReport;
  vehicles: Vehicle[];
  tags: Tag[];
  totalRecords: number;
}

export interface DetectionsFilter {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  vehicles: Vehicle[];
  tags: Tag[];
}

export interface DetectionsRequest {
  viewAsUser?: string;
  pageNumber: number;
  vehicleIds: string[];
  tagIds: string[];
  dateRangeStart: string | undefined;
  dateRangeEnd: string | undefined;
  pageSize: number;
  sortItems: SortItem[];
}

export interface DetectionResponse {
  vehicles: Vehicle[];
  tags: Tag[];
  detections: DetectionRecord[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}

function getDashboard(request: DashboardRequest): Promise<DashboardResponse> {
  return fetchFromApiResource(`api/v1/logrecords/dashboard`, "POST", undefined, JSON.stringify(request)).then((response) =>
    handleJsonResponse<DashboardResponse>(response)
  );
}

function getDetections(request: DetectionsRequest): Promise<DetectionResponse> {
  return fetchFromApiResource(`api/v1/logrecords/detections`, "POST", undefined, JSON.stringify(request)).then((response) =>
    handleJsonResponse<DetectionResponse>(response)
  );
}

const logRecordService = {
  getDashboard,
  getDetections,
};

export default logRecordService;
