import { atom } from "recoil";
import { SortItem } from "src/services";

export const detectionsTableControlState = atom({
  key: "DASHBOARD_detections_table_control",
  default: {
    pageNumber: 0,
    pageSize: 10,
    sortItems: [] as SortItem[],
  },
});
