import { atom } from "recoil";
import { DashboardResponse } from "src/services";

export const dashboardDataState = atom<DashboardResponse>({
  key: "DASHBOARD_dashboard_data",
  default: {
    dashboardReport: {
      startDate: new Date(),
      endDate: new Date(),
      averageDetectionsPerMonth: { number: 0, change: 0 },
      averageDetectionsPerWeek: { number: 0, change: 0 },
      averageDetectionsPerDay: { number: 0, change: 0 },
      totalDetections: { items: [], entries: [] },
      topFiveVehiclesWithDetections: [],
      topFiveTagsWithDetections: [],
    },
    vehicles: [],
    tags: [],
    totalRecords: 0,
  },
});
