import { atom } from "recoil";
import { User } from "src/services";

function getUserFromLocalStorage(): User | undefined {
  var localUserData = localStorage.getItem("user");
  if (localUserData == null) {
    return undefined;
  }

  try {
    var user: User = JSON.parse(localUserData);
  } catch {
    localStorage.removeItem("user");
    return undefined;
  }

  return user;
}

export const userState = atom({
  key: "user",
  default: getUserFromLocalStorage(),
});
