import { fetchFromApiResource, handleJsonResponse } from "src/helpers";

export interface LogBundle {
  logBundleId: number;
  vehicleName: string;
  date: Date;
  logBundleFileName: string;
}

export interface GetBundlesResponse {
  logBundles: LogBundle[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}

export interface LogBundleRecord {
  vehicleId: string;
  recordDate: string;
  recordTime: string;
  recordType: string;
  description: string;
  parameter1: string;
  parameter2: string;
  parameter3: string;
  parameter4: string;
}

export interface LogBundleResponse {
  logBundleRecords: LogBundleRecord[];
}

function getLogBundles(
  pageNumber: number,
  pageSize: number
): Promise<GetBundlesResponse> {
  return fetchFromApiResource(
    `api/v1/logrecords/bundles?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    "GET"
  ).then((response) => handleJsonResponse<GetBundlesResponse>(response));
}

function getLogBundle(bundleId: number): Promise<LogBundleResponse> {
  return fetchFromApiResource(
    `api/v1/logrecords/bundles/${bundleId}`,
    "GET"
  ).then((response) => handleJsonResponse<LogBundleResponse>(response));
}

function deleteLogBundle(bundleId: number): Promise<boolean> {
  return fetchFromApiResource(
    `api/v1/logrecords/bundles/${bundleId}`,
    "DELETE"
  ).then((_) => true);
}

const logBundleService = {
  getLogBundles,
  getLogBundle,
  deleteLogBundle,
};

export default logBundleService;
