import "./App.css";

import React from "react";
import { Redirect, Router } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";

import { CssBaseline } from "@material-ui/core";
import { muiTreasuryPreset, Root } from "@mui-treasury/layout";

import { userState } from "./atoms";
import { AuthRequiredRouteWithLayout, RouteWithLayout } from "./components";
import { history } from "./helpers";
import { MainLayout, MinLayout } from "./layouts";
import theme from "./theme";
import Dashboard from "./views/Dashboard";
import LogBundles from "./views/LogBundles";
import Login from "./views/Login";
import Readers from "./views/Readers";
import Tags from "./views/Tags";
import UserManagement from "./views/UserManagement";
import Vehicles from "./views/Vehicles";

const AppRouter: React.FC = () => {
  const user = useRecoilValue(userState);

  const isUserAdmin = user && user.token && user.roles.indexOf("admin") > -1;

  return (
    <Router history={history}>
      {(user === undefined || !user.token) && <Redirect to="/login" />}

      <RouteWithLayout path="/login" component={Login} layout={MinLayout} />

      <AuthRequiredRouteWithLayout path="/" component={Dashboard} layout={MainLayout} />
      <AuthRequiredRouteWithLayout path="/uploadedlogs" component={LogBundles} layout={MainLayout} />
      <AuthRequiredRouteWithLayout path="/tags" component={Tags} layout={MainLayout} />
      <AuthRequiredRouteWithLayout path="/vehicles" component={Vehicles} layout={MainLayout} />

      {isUserAdmin && (
        <div>
          <AuthRequiredRouteWithLayout path="/admin/users" component={UserManagement} layout={MainLayout} />
          <AuthRequiredRouteWithLayout path="/admin/readers" component={Readers} layout={MainLayout} />
        </div>
      )}
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Root theme={theme} config={muiTreasuryPreset}>
        <CssBaseline />
        <AppRouter />
      </Root>
    </RecoilRoot>
  );
};

export default App;
