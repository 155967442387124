import React, { useState } from "react";

import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import { CreateUserRequest, userService } from "../../../services";

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  saveProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

interface CreateUserDialogProps {
  open: boolean;
  onClose(): void;
}

const CreateUserDialog: React.FC<CreateUserDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [roles, setRoles] = useState<string[]>(["user"]);
  const [password, setPassword] = useState<string>("");

  const [userIdError, setUserIdError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const availableRoles = ["admin", "user"];

  const isEmailValid = () => {
    // If in format something@localhost
    if (/^[^@]*@(localhost)$/.test(email)) {
      return true;
    }

    // If in format something@domain.com
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return true;
    }

    return false;
  };

  const isInputValid = () => {
    var validInput = true;

    setUserIdError("");
    setEmailError("");
    setPasswordError("");

    if (!userId) {
      setUserIdError("Must specify a username");
      validInput = false;
    }

    if (!isEmailValid()) {
      setEmailError("Must specify a valid e-mail address");
      validInput = false;
    }

    if (!password || password.length < 6) {
      setPasswordError("Password must contain at least 6 characters");
      validInput = false;
    }

    return validInput;
  };

  const handleUserIdInputChange = (value: string) => {
    setUserId(value);
    setUserIdError("");
  };

  const handleEmailInputChange = (value: string) => {
    setEmail(value);
    setEmailError("");
  };

  const handlePasswordInputChange = (value: string) => {
    setPassword(value);
    setPasswordError("");
  };

  const handleCreate = () => {
    if (saving) {
      return;
    }

    if (!isInputValid()) {
      return;
    }

    setSaving(true);

    const createUserRequest: CreateUserRequest = {
      userId: userId,
      email: email,
      roles: roles,
      password: password
    };
    userService.createUser(createUserRequest).then(() => {
      setSaving(false);
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Create User</DialogTitle>

      <DialogContent>
        <TextField
          label="Username"
          error={userIdError !== ""}
          helperText={userIdError}
          value={userId}
          margin="dense"
          fullWidth
          required
          autoFocus
          onChange={event => handleUserIdInputChange(event.target.value)}
        />

        <TextField
          label="E-Mail"
          error={emailError !== ""}
          helperText={emailError}
          value={email}
          type="email"
          margin="dense"
          required
          fullWidth
          disabled={saving}
          onChange={event => handleEmailInputChange(event.target.value)}
        />

        <FormControl fullWidth required disabled={saving} margin="dense">
          <InputLabel id="roles-label">Roles</InputLabel>
          <Select
            multiple
            value={roles}
            onChange={event => setRoles(event.target.value as string[])}
            renderValue={selected => (
              <div className={classes.chips}>
                {(selected as string[]).sort().map(value => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
          >
            {availableRoles.map(role => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Password"
          error={passwordError !== ""}
          helperText={passwordError}
          value={password}
          type="password"
          margin="dense"
          required
          fullWidth
          disabled={saving}
          onChange={event => handlePasswordInputChange(event.target.value)}
        />

        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary" disabled={saving}>
            Create
          </Button>
        </DialogActions>

        {saving && <CircularProgress size={24} className={classes.saveProgress} />}
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserDialog;
