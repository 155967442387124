import React from "react";

import { Button, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
    fontSize: "1.2em",
  },
  user: {
    fontWeight: "bold",
  },
}));

interface ViewAsUserAlertProps {
  viewAsUserId: string;
  onExitViewAsUser(): void;
}

const ViewAsUserAlert: React.FC<ViewAsUserAlertProps> = ({
  viewAsUserId,
  onExitViewAsUser,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert
        className={classes.alert}
        severity="error"
        action={<Button onClick={onExitViewAsUser}>Back to my user</Button>}
      >
        Impersonating user <span className={classes.user}>{viewAsUserId}</span>
      </Alert>
    </div>
  );
};

export default ViewAsUserAlert;
