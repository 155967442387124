import { handleJsonResponse, fetchFromApiResource } from "../helpers";
import { PageQueryResult } from "../helpers";

export interface User {
  userId: string;
  email: string;
  roles: string[];
  isDeactivated: boolean;
  token: string;
}

export interface ReaderDto {
  id: string;
  displayName: string;
}

export interface UserDto {
  userId: string;
  email: string;
  roles: string[];
  readers: ReaderDto[];
  isDeactivated: boolean;
}

export interface GetUsersViewReponse {
  users: PageQueryResult<UserDto>;
  readers: ReaderDto[];
}

export interface UpdateUserRequest {
  userId: string;
  email: string;
  roles: string[];
  readers: ReaderDto[];
}

export interface CreateUserRequest {
  userId: string;
  email: string;
  roles: string[];
  password: string;
}

function authenticate(userId: string, password: string): Promise<User> {
  return fetchFromApiResource(`api/authenticate`, "POST", undefined, JSON.stringify({ username: userId, password: password }))
    .then(response => handleJsonResponse<User>(response))
    .then(user => {
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getUsers(page: number, pageSize: number): Promise<GetUsersViewReponse> {
  return fetchFromApiResource(`api/v1/users?page=${page}&pageSize=${pageSize}`, "GET").then(response =>
    handleJsonResponse<GetUsersViewReponse>(response)
  );
}

function getUser(userId: string): Promise<User> {
  return fetchFromApiResource(`api/v1/users/${userId}`, "GET").then(response => handleJsonResponse<User>(response));
}

function createUser(createUserRequest: CreateUserRequest): Promise<void> {
  return fetchFromApiResource(`api/v1/users`, "POST", undefined, JSON.stringify(createUserRequest)).then(
    () => new Promise<void>(resolve => resolve())
  );
}

function updateUser(updateUserRequest: UpdateUserRequest): Promise<void> {
  return fetchFromApiResource(`api/v1/users`, "PUT", undefined, JSON.stringify(updateUserRequest)).then(
    () => new Promise<void>(resolve => resolve())
  );
}

function deactivateUser(userId: string): Promise<void> {
  return fetchFromApiResource(`api/v1/users/${userId}`, "DELETE").then(
    () => new Promise<void>(resolve => resolve())
  );
}

function activateUser(userId: string): Promise<void> {
  return fetchFromApiResource(`api/v1/users/${userId}/activate`, "PUT").then(
    () => new Promise<void>(resolve => resolve())
  );
}

function changePassword(userId: string, password: string): Promise<void> {
  return fetchFromApiResource(`api/v1/users/${userId}/changepassword`, "PUT", undefined, JSON.stringify({ password: password })).then(
    () => new Promise<void>(resolve => resolve())
  );
}

const userService = {
  authenticate,
  logout,
  getUsers,
  getUser,
  createUser,
  updateUser,
  deactivateUser,
  activateUser,
  changePassword
};

export default userService;
