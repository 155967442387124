import React, { useState } from "react";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { UserDto, userService } from "../../../services";

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  saveProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

interface ResetUserPasswordDialogProps {
  user: UserDto;
  open: boolean;
  onClose(): void;
}

const ResetUserPasswordDialog: React.FC<ResetUserPasswordDialogProps> = ({ user, open, onClose }) => {
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState("");

  const isInputValid = () => {
    var validInput = true;

    setPasswordError("");

    if (!password || password.length < 6) {
      setPasswordError("Password must contain at least 6 characters");
      validInput = false;
    }

    return validInput;
  };

  const handlePasswordInputChange = (value: string) => {
    setPassword(value);
    setPasswordError("");
  };

  const handleSave = () => {
    if (saving) {
      return;
    }

    if (!isInputValid()) {
      return;
    }

    setSaving(true);

    userService.changePassword(user.userId, password).then(() => {
      setSaving(false);
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>

      <DialogContent>
        <TextField label="Username" value={user.userId} margin="dense" fullWidth disabled />

        <TextField
          label="Password"
          error={passwordError !== ""}
          helperText={passwordError}
          value={password}
          type="password"
          margin="dense"
          required
          fullWidth
          autoFocus
          disabled={saving}
          onChange={event => handlePasswordInputChange(event.target.value)}
        />

        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            Save
          </Button>
        </DialogActions>

        {saving && <CircularProgress size={24} className={classes.saveProgress} />}
      </DialogContent>
    </Dialog>
  );
};

export default ResetUserPasswordDialog;
