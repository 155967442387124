import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { storage, StorageKey } from "src/helpers";

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import Alert from "@material-ui/lab/Alert";

import { Page, TotalDetectionsLineChart } from "../../components";
import { DetectionsFilter, logRecordService } from "../../services";
import {
  dashboardDataState,
  dashboardLoadingState,
  DefaultDetectionsFilter,
  detectionsDataState,
  detectionsFilterState,
  detectionsLoadingState,
  detectionsTableControlState,
} from "./atoms";
import {
  DetectionsDataGrid,
  FilterDrawer,
  NumberSummaryCard,
  TopFiveSummaryTable,
} from "./components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(2),
    },
    showFilterButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const DashboardReport: React.FC = () => {
  const classes = useStyles();
  const detectionsTableControlData = useRecoilValue(
    detectionsTableControlState
  );

  const [dashboardResponse, setDashboardResponse] =
    useRecoilState(dashboardDataState);
  const [, setDetectionsResponse] = useRecoilState(detectionsDataState);
  const [detectionsFilter, setDetectionsFilter] = useRecoilState(
    detectionsFilterState
  );
  const [dashboardLoading, setDashboardLoading] = useRecoilState(
    dashboardLoadingState
  );
  const [, setDetectionsLoading] = useRecoilState(detectionsLoadingState);

  const { pageNumber, pageSize, sortItems } = detectionsTableControlData;

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [pendingFilter, setPendingFilter] = useState<DetectionsFilter>({
    ...detectionsFilter,
  });

  const {
    averageDetectionsPerMonth,
    averageDetectionsPerWeek,
    averageDetectionsPerDay,
    totalDetections,
    topFiveVehiclesWithDetections,
    topFiveTagsWithDetections,
  } = dashboardResponse.dashboardReport;

  useEffect(() => {
    storage.saveSessionData(
      StorageKey.detectionsFilter,
      JSON.stringify(detectionsFilter)
    );
  }, [detectionsFilter]);

  useEffect(() => {
    setDashboardLoading(true);

    var dateRangeStartString: string | undefined = undefined;
    var dateRangeEndString: string | undefined = undefined;

    if (detectionsFilter.startDate) {
      dateRangeStartString = moment(detectionsFilter.startDate).format(
        "YYYY-MM-DD"
      );
    }

    if (detectionsFilter.endDate) {
      dateRangeEndString = moment(detectionsFilter.endDate).format(
        "YYYY-MM-DD"
      );
    }

    logRecordService
      .getDashboard({
        vehicleIds: detectionsFilter.vehicles.map((x) => x.id),
        tagIds: detectionsFilter.tags.map((x) => x.id),
        dateRangeStart: dateRangeStartString,
        dateRangeEnd: dateRangeEndString,
      })
      .then((dashboardResponse) => {
        setDashboardResponse(dashboardResponse);
        setDashboardLoading(false);
      })
      .catch((exception) => {
        console.log(exception.message);
      });
  }, [detectionsFilter, setDashboardLoading, setDashboardResponse]);

  useEffect(() => {
    var dateRangeStartString: string | undefined = undefined;
    var dateRangeEndString: string | undefined = undefined;

    if (detectionsFilter.startDate) {
      dateRangeStartString = moment(detectionsFilter.startDate).format(
        "YYYY-MM-DD"
      );
    }

    if (detectionsFilter.endDate) {
      dateRangeEndString = moment(detectionsFilter.endDate).format(
        "YYYY-MM-DD"
      );
    }

    setDetectionsLoading(true);

    logRecordService
      .getDetections({
        vehicleIds: detectionsFilter.vehicles.map((x) => x.id),
        tagIds: detectionsFilter.tags.map((x) => x.id),
        dateRangeStart: dateRangeStartString,
        dateRangeEnd: dateRangeEndString,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortItems: sortItems,
      })
      .then((detectionsResponse) => {
        setDetectionsResponse(detectionsResponse);
        setDetectionsLoading(false);
      })
      .catch((exception) => {
        console.log(exception.message);
      });
  }, [
    detectionsFilter,
    pageNumber,
    pageSize,
    sortItems,
    setDetectionsLoading,
    setDetectionsResponse,
  ]);

  const applyFilter = () => {
    setDetectionsFilter(pendingFilter);
  };

  const clearFilter = () => {
    setDetectionsFilter({ ...DefaultDetectionsFilter });
    setPendingFilter({ ...DefaultDetectionsFilter });
  };

  const handleFilterDrawerClose = () => {
    setFilterDrawerOpen(false);
  };

  return (
    <Page
      section="Analytics"
      title="Safety Overview"
      titleActions={
        <Button
          className={classes.showFilterButton}
          variant="outlined"
          color="primary"
          startIcon={<FilterListIcon />}
          onClick={() => setFilterDrawerOpen(true)}
        >
          Show Filters
        </Button>
      }
    >
      {!dashboardLoading && !dashboardResponse.totalRecords && (
        <Alert
          className={classes.alert}
          severity="warning"
          action={<Button onClick={clearFilter}>Clear Filters</Button>}
        >
          No data is available for the selected filters
        </Alert>
      )}

      <Grid container spacing={4}>
        <Grid item lg={4} xs={12}>
          <NumberSummaryCard
            title="Average monthly detections for period"
            number={
              averageDetectionsPerMonth && averageDetectionsPerMonth.number
            }
            loading={dashboardLoading}
            icon={<DateRangeIcon />}
            iconColor="blue"
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <NumberSummaryCard
            title="Average weekly detections for period"
            number={averageDetectionsPerWeek && averageDetectionsPerWeek.number}
            loading={dashboardLoading}
            icon={<ViewWeekIcon />}
            iconColor="green"
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <NumberSummaryCard
            title="Average daily detections for period"
            number={averageDetectionsPerDay && averageDetectionsPerDay.number}
            loading={dashboardLoading}
            icon={<EqualizerIcon />}
            iconColor="orange"
          />
        </Grid>
        <Grid item xs={12}>
          <TotalDetectionsLineChart
            title="Total Detections"
            data={totalDetections}
            loading={dashboardLoading}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TopFiveSummaryTable
            title="Top 5 Vehicle Detections"
            rows={topFiveVehiclesWithDetections}
            nameColumnTitle="Vehicle Name"
            valueColumnTitle="Detections"
            loading={dashboardLoading}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TopFiveSummaryTable
            title="Top 5 Tag Detections"
            rows={topFiveTagsWithDetections}
            nameColumnTitle="Tag Name"
            valueColumnTitle="Detections"
            loading={dashboardLoading}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DetectionsDataGrid />
      </Grid>

      <FilterDrawer
        open={filterDrawerOpen}
        filter={pendingFilter}
        setFilter={setPendingFilter}
        onApplyFilter={applyFilter}
        onClearFilter={clearFilter}
        onClose={handleFilterDrawerClose}
      />
    </Page>
  );
};

interface DashboardProps {
  location: {
    search: string;
  };
}

const Dashboard: React.FC<DashboardProps> = ({ location }) => {
  return <DashboardReport />;
};

export default Dashboard;
