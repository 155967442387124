import React, { useEffect, useState } from "react";
import { Page } from "src/components";
import { LogBundle, logBundleService } from "src/services";

import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LogBundleDataGrid from "./components/LogBundleDataGrid";

const useStyles = makeStyles((theme) => ({
  actionButtonColumn: {
    textAlign: "right",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const LogBundles: React.FC = () => {
  const classes = useStyles();

  const [logBundles, setLogBundles] = useState<LogBundle[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    setLoading(true);
    logBundleService.getLogBundles(pageNumber, pageSize).then((result) => {
      setLogBundles(result.logBundles);
      setTotalRecords(result.totalRecords);
      setLoading(false);
    });
  }, [pageSize, pageNumber]);

  const deleteLogBundle = (logBundleId: number) => {
    setLoading(true);

    logBundleService.deleteLogBundle(logBundleId).then(() => {
      setLogBundles((logBundles) =>
        logBundles.filter((l) => l.logBundleId !== logBundleId)
      );
      setLoading(false);
    });
  };

  return (
    <Page section="Analytics" title="Bundles">
      <Paper className={classes.paper}>
        <LogBundleDataGrid
          logBundles={logBundles}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalRecords={totalRecords}
          loading={loading}
          onPageNumberChange={setPageNumber}
          onPageSizeChange={setPageSize}
          onDeleteLogBundle={deleteLogBundle}
        />
      </Paper>
    </Page>
  );
};

export default LogBundles;
