import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "src/atoms";
import { Loading, Page } from "src/components";

import {
  Button,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { history } from "../../helpers";
import { ReaderDto, UserDto, userService } from "../../services";
import { EditUserDialog, ResetUserPasswordDialog } from "./components";
import CreateUserDialog from "./components/CreateUserDialog";
import storageService, { StorageKey } from "src/helpers/Storage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerContainer: {
    marginTop: theme.spacing(3),
    textAlign: "right",
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 550,
    },
  })
)(TableCell);

interface ActionMenuProps {
  onEditUser(): void;
  onChangePassword(): void;
  onImpersonateUser(): void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
  onEditUser,
  onChangePassword,
  onImpersonateUser: onViewDashboard,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleEditUser = () => {
    closeMenu();
    onEditUser();
  };

  const handleChangePassword = () => {
    closeMenu();
    onChangePassword();
  };

  const handleViewDashboard = () => {
    closeMenu();
    onViewDashboard();
  };

  return (
    <React.Fragment>
      <Button
        endIcon={<ExpandMoreIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => handleEditUser()}>Edit</MenuItem>
        <MenuItem onClick={() => handleChangePassword()}>
          Change password
        </MenuItem>
        <MenuItem onClick={() => handleViewDashboard()}>
          Impersonate user
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const UserManagement: React.FC = () => {
  const classes = useStyles();

  const user = useRecoilValue(userState);

  const [openEditUserDialog, setOpenEditUserDialog] = React.useState(false);
  const [openCreateUserDialog, setOpenCreateUserDialog] = React.useState(false);
  const [openResetUserPasswordDialog, setOpenResetUserPasswordDialog] =
    React.useState(false);
  const [userBeingEdited, setUserBeingEdited] = React.useState<UserDto>();
  const [userForChangePassword, setUserForChangePassword] =
    React.useState<UserDto>();

  const [users, setUsers] = useState<UserDto[]>([]);
  const [availableReaders, setAvailableReaders] = useState<ReaderDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    userService.getUsers(0, 10000).then((result) => {
      setUsers(result.users.data);
      setAvailableReaders(result.readers);
      setLoading(false);
    });
  }, [shouldRefresh]);

  const refresh = () => {
    setShouldRefresh(!shouldRefresh);
  };

  const handleCreateUser = () => {
    setOpenCreateUserDialog(true);
  };

  const toggleUserActivationStatus = (user: UserDto) => {
    var changeStatus = user.isDeactivated
      ? userService.activateUser(user.userId)
      : userService.deactivateUser(user.userId);
    changeStatus.then(() => setShouldRefresh(!shouldRefresh));
  };

  const handleEditUser = (user: UserDto) => {
    setUserBeingEdited(user);
    setOpenEditUserDialog(true);
  };

  const handleChangePassword = (user: UserDto) => {
    setUserForChangePassword(user);
    setOpenResetUserPasswordDialog(true);
  };

  const handleImpersonateUser = (user: UserDto) => {
    storageService.saveSessionData(StorageKey.viewAsUser, user.userId);
    history.push("/");
  };

  const handleEditUserDialogClose = () => {
    setUserBeingEdited(undefined);
    setOpenEditUserDialog(false);
    refresh();
  };

  const handleCreateUserDialogClose = () => {
    setOpenCreateUserDialog(false);
    refresh();
  };

  const handleResetUserPasswordDialogClose = () => {
    setUserForChangePassword(undefined);
    setOpenResetUserPasswordDialog(false);
    refresh();
  };

  return (
    <Page
      section="Admin"
      title="Users"
      titleActions={
        <Button variant="contained" onClick={handleCreateUser} color="primary">
          Create User
        </Button>
      }
    >
      {userBeingEdited && (
        <EditUserDialog
          user={userBeingEdited}
          availableReaders={availableReaders}
          open={openEditUserDialog}
          onClose={handleEditUserDialogClose}
        />
      )}
      {openCreateUserDialog && (
        <CreateUserDialog
          open={openCreateUserDialog}
          onClose={handleCreateUserDialogClose}
        />
      )}
      {userForChangePassword && (
        <ResetUserPasswordDialog
          user={userForChangePassword}
          open={openResetUserPasswordDialog}
          onClose={handleResetUserPasswordDialogClose}
        />
      )}

      <Paper className={classes.paper}>
        <Table aria-label="users">
          <TableHead>
            <TableRow>
              <StyledTableCell>User</StyledTableCell>
              <StyledTableCell>E-mail</StyledTableCell>
              <StyledTableCell>Roles</StyledTableCell>
              <StyledTableCell>Assigned Readers</StyledTableCell>
              <StyledTableCell>Is Deactivated</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row, index) => (
              <TableRow key={row.userId}>
                <TableCell>{row.userId}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  {row.roles.sort().map((role) => (
                    <Chip key={role} className={classes.chip} label={role} />
                  ))}
                </TableCell>
                <TableCell>
                  {row.readers &&
                    row.readers
                      .sort((a, b) => (a.id > b.id ? 1 : -1))
                      .map((reader) => (
                        <Chip
                          key={reader.id}
                          className={classes.chip}
                          label={reader.displayName}
                        />
                      ))}
                </TableCell>
                <TableCell>
                  <Checkbox
                    disabled={user && row.userId === user.userId}
                    checked={row.isDeactivated}
                    onClick={() => toggleUserActivationStatus(row)}
                    inputProps={{ "aria-label": "is user deactivated" }}
                  />
                </TableCell>
                <TableCell>
                  <ActionMenu
                    onEditUser={() => handleEditUser(row)}
                    onChangePassword={() => handleChangePassword(row)}
                    onImpersonateUser={() => handleImpersonateUser(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {loading && <Loading />}
    </Page>
  );
};

export default UserManagement;
