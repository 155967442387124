import moment from "moment";
import { atom } from "recoil";
import { storage, StorageKey } from "src/helpers";
import { DetectionsFilter } from "src/services";

export const DefaultDetectionsFilter: DetectionsFilter = {
  startDate: moment().add(-12, "months"),
  endDate: moment(),
  vehicles: [],
  tags: [],
};

function loadDetectionsFilterFromSessionStorage(): DetectionsFilter | undefined {
  const savedDetectionsFilterData = storage.getSessionData(StorageKey.detectionsFilter);
  if (!savedDetectionsFilterData) {
    return undefined;
  }

  return JSON.parse(savedDetectionsFilterData) as DetectionsFilter;
}

export const detectionsFilterState = atom({
  key: "DASHBOARD_detections_filter",
  default: { ...(loadDetectionsFilterFromSessionStorage() || DefaultDetectionsFilter) },
});
