import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "src/atoms";
import storageService, { StorageKey } from "src/helpers/Storage";
import { userService } from "src/services";

import {
  ClickAwayListener,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloudIcon from "@material-ui/icons/Cloud";

import ViewAsUserAlert from "./ViewAsUserAlert";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  root: {
    display: "flex",
  },
  logoIcon: {
    marginRight: 5,
  },
  header: {
    minWidth: 0,
    flexGrow: 1,
    marginTop: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  userProfileText: {
    marginLeft: 5,
  },
}));

const ProfileButton: React.FC = () => {
  const classes = useStyles();
  const [user, setUser] = useRecoilState(userState);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const profileButtonAnchorRef = React.useRef(null);

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setProfileMenuOpen(false);
    }
  };

  const handleLogoutClicked = () => {
    userService.logout();
    setUser(undefined);
    setProfileMenuOpen(false);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        ref={profileButtonAnchorRef}
        onClick={() => setProfileMenuOpen(!profileMenuOpen)}
      >
        <AccountCircleIcon />
        <Typography
          component="p"
          variant="body1"
          className={classes.userProfileText}
        >
          {user?.userId}
        </Typography>
      </IconButton>
      <Popper
        open={profileMenuOpen}
        anchorEl={profileButtonAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setProfileMenuOpen(false)}>
                <MenuList
                  autoFocusItem={profileMenuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleLogoutClicked}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const HeaderContent: React.FC = () => {
  const classes = useStyles();

  const user = useRecoilValue(userState);

  const viewAsUserId = storageService.getSessionData(StorageKey.viewAsUser);

  const handleExitViewAsUser = () => {
    storageService.clearSessionData(StorageKey.viewAsUser);
    window.location.reload();
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <IconButton edge="start" color="inherit" className={classes.logoIcon}>
          <CloudIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.header}
        >
          BodyGuard Cloud
        </Typography>
        {user && <ProfileButton />}
      </div>
      {viewAsUserId ? (
        <div>
          <ViewAsUserAlert
            viewAsUserId={viewAsUserId}
            onExitViewAsUser={handleExitViewAsUser}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HeaderContent;
