import { fetchFromApiResource, handleJsonResponse } from "../helpers";

export interface Reader {
  id: string;
  systemName: string;
  displayName: string;
  associatedUserIds: string[];
  lastUploadDateTime: Date;
}

export interface UpdateReaderRequest {
  id: string;
  displayName: string;
}

export interface GetReadersResponse {
  readers: Reader[];
}

function getReaders(): Promise<GetReadersResponse> {
  return fetchFromApiResource(`api/v1/logrecords/readers`, "GET").then(
    (response) => handleJsonResponse<GetReadersResponse>(response)
  );
}

function updateReader(updateReaderRequest: UpdateReaderRequest): Promise<void> {
  return fetchFromApiResource(
    `api/v1/logrecords/readers`,
    "PUT",
    undefined,
    JSON.stringify(updateReaderRequest)
  ).then(() => new Promise<void>((resolve) => resolve()));
}

const readerService = {
  getReaders,
  updateReader,
};

export default readerService;
