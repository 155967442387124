import React from "react";
import { Loading } from "src/components";

import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },
    tablePaper: {
      minHeight: "270px",
      marginBottom: theme.spacing(1),
    },
    title: {
      textAlign: "left",
      margin: theme.spacing(2),
    },
    tableCell: {
      paddingLeft: theme.spacing(2),
    },
    footer: {
      margin: theme.spacing(2),
      textAlign: "right",
    },
  })
);

interface Data {
  name: string;
  detections: number;
}

interface TopFiveSummaryTable {
  title: string;
  rows: Data[];
  nameColumnTitle: string;
  valueColumnTitle: string;
  loading: boolean;
}

const TopFiveSummaryTable: React.FC<TopFiveSummaryTable> = ({ title, rows, nameColumnTitle, valueColumnTitle, loading }) => {
  const classes = useStyles();

  return (
    <Paper>
      <div className={classes.wrapper}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography component="h1" variant="h6" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TableContainer className={classes.tablePaper}>
              <Table size="small">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell className={classes.tableCell} style={{ display: "flex" }}>
                        <div>
                          <Typography variant="caption">{nameColumnTitle}</Typography>
                          <Typography variant="body2" component="p">
                            {row.name}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography variant="caption">{valueColumnTitle}</Typography>
                        <Typography variant="body2">{row.detections.toLocaleString("en-US")}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {loading && <Loading />}
      </div>
    </Paper>
  );
};

export default TopFiveSummaryTable;
