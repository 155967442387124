import React from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, .3)"
  },
  circularProgress: {
    position: "absolute",
    fontSize: 20,
    top: "calc(45% - 10px)",
    left: "calc(50% - 10px)"
  }
}));

const Loading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.circularProgress} />
    </div>
  );
};

export default Loading;
